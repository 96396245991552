// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookit-js": () => import("./../../../src/pages/cookit.js" /* webpackChunkName: "component---src-pages-cookit-js" */),
  "component---src-pages-cookit-privacy-js": () => import("./../../../src/pages/cookit/privacy.js" /* webpackChunkName: "component---src-pages-cookit-privacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maleva-js": () => import("./../../../src/pages/maleva.js" /* webpackChunkName: "component---src-pages-maleva-js" */),
  "component---src-pages-maleva-privacy-js": () => import("./../../../src/pages/maleva/privacy.js" /* webpackChunkName: "component---src-pages-maleva-privacy-js" */),
  "component---src-pages-mole-privacy-js": () => import("./../../../src/pages/mole/privacy.js" /* webpackChunkName: "component---src-pages-mole-privacy-js" */),
  "component---src-pages-superlista-js": () => import("./../../../src/pages/superlista.js" /* webpackChunkName: "component---src-pages-superlista-js" */),
  "component---src-pages-superlista-privacy-js": () => import("./../../../src/pages/superlista/privacy.js" /* webpackChunkName: "component---src-pages-superlista-privacy-js" */)
}

